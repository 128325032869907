<template>
    <div v-if="invalidItems" class="mt-1">
        <div v-for="(item, id) in invalidItems" style="border: 1px solid black;" class="p-1 mb-1" :key="'invalid_item_' + id">
            <div v-if="item.newQuantity === 0">
                Izdelka
                <router-link :to="{name:'web-shop-item', params: { item_id: id }}">
                    <b>{{item.name}}</b>
                </router-link> 
                ni več na zalogi, zato smo ga odstranili iz košarice.
            </div>
            <div v-else>
                Izdelku <b>{{item.name}}</b> v vaši košarici smo zmanjšali količino iz {{item.oldQuantity}} na {{item.newQuantity}}.
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            invalidItems:{
                type: Object
            }
        }
    }
</script>