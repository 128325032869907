<template>
    <label class="container" :style="customStyle"><slot/>
        <input type="checkbox" :value="value" v-model="model" :name="name"/>
        <span class="checkmark"></span>
    </label>
</template>

<script>
    export default
        {
            model: {
                prop: 'checked',
                event: 'change'
            },
            props: {
                name: {
                    type: String,
                    required: true
                },
                checked: {
                    type: [Array, Boolean, Number, String],
                    default: false
                },
                value: {
                    default: null
                },
                customStyle: {
                    type: String,
                    default: ''
                }
            },
            computed: {
                model: {
                    get() {
                        return this.checked
                    },
                    set(value) {
                        this.$emit('change', value)
                    }
                }
            }
        }
</script>

<style scoped>
.container {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 0px;
  cursor: pointer;
  font-size: 17px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  border: 1px solid black;
}
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}
.container input:checked ~ .checkmark:after {
  display: block;
}
.container .checkmark:after {
  left: 7px;
  top: 1px;
  width: 10px;
  height: 18px;
  border: solid black;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
</style>