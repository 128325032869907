<template>
    <div>
        <div v-if="!showEndOrderMessage">
            <b-row>
                <b-col lg="6" cols="12" v-if="isLoggedIn">
                    <!--first tab-->
                    <div style="background-color: #7da5d7; border: none; display: flex; align-items: center"
                         v-bind:class="{'finish' : finishFirstTab}">
                        <h3 class="bold h3_align_center w-75 pl-1 my-1" style="color: white; text-align: start;">1. Podatki
                            kupca<span
                                style="font-weight: normal"> (Iz registracije)</span>
                        </h3>
                        <div v-if="finishFirstTab" class="w-25 d-flex justify-content-end">
                            <p class="h3_align_center1 my-1 px-1 on-click_text bold" style="color: white"
                               v-on:click="finishFirstTab=false">Spremeni</p>
                        </div>
                    </div>
                    <div class="p-1" v-if="!finishFirstTab">
                        <div>
                            <h3  class="bold pb-0 mb-0" style="font-size: 14px">
                                {{object.isDeliveryAddressDifferentThanInvoice ? "Naslov za dostavo" : "Naslov za račun in dostavo"}}
                            </h3>
                            <div class="hr"/>
                            <display-address-card :show-address-button="!object.isDeliveryAddressDifferentThanInvoice && hasPrimaryAdress" :address="invoiceAddress" v-on:open-second-address-model="ModalShowSecondaryAddress=true" v-on:update-delivery-address="changeDeliveryAddress"/>
                            <h3 v-if="object.isDeliveryAddressDifferentThanInvoice" class="bold pb-0 mb-0 mt-2" style="font-size: 14px">Naslov za dostavo</h3>
                            <div class="hr"/>
                        </div>
                        <div v-if="object.isDeliveryAddressDifferentThanInvoice">
                            <display-address-card :show-address-button="object.isDeliveryAddressDifferentThanInvoice && hasPrimaryAdress" :address="deliveryAddress" v-on:open-second-address-model="ModalShowSecondaryAddress=true" v-on:update-delivery-address="changeDeliveryAddress"/>
                            <div class="hr"/>
                        </div>
                        <div class="mt-2 d-flex justify-content-center">
                            <b-button v-if="hasPrimaryAdress" pill class="shoping-button button-mobile" @click="validationFirstTab">Naslednji korak
                            </b-button>
                            <div v-else>
                                <div style="color: red;">
                                    Nimate veljavnega primarnega naslova, za nadaljevanje naročila prosim vnesite naslov.
                                </div>
                                <div class="d-flex justify-content-center w-100 mt-2">
                                    <b-button  pill class="shoping-button w-50" @click="setPrimaryAdress">Nastavi primarni naslov
                                    </b-button>
                                </div>
                                <AddPrimaryAdressForm :modal-show="addPrimaryAdress" title="Vnesite vaš naslov" v-on:confirm-action="savePrimaryAddress" @hide="addPrimaryAdress=false" @close="addPrimaryAdress=false"/>
                            </div>
                               
                        </div>
                    </div>

                    <!--second tab-->
                    <div class="mt-1"
                         v-bind:class="{'finish' : finishSecondTab, 'show-collapse' : finishFirstTab}">

                        <div v-if="!finishFirstTab" style="background-color: #7da5d7; border: none" class="w-100">
                            <h3 class="bold" style="color: white; padding: 0.5rem">2. Dostava in plačilo</h3>
                        </div>
                        <div v-else style="background-color: #7da5d7; border: none; display: flex">
                            <h3 class="bold h3_align_center w-75 pl-1 my-1" style="color: white; text-align: start;">2.
                                Dostava in plačilo<span
                                    style="font-weight: normal"> (Iz registracije)</span>
                            </h3>
                            <div v-if="finishSecondTab" class="w-25 d-flex justify-content-end">
                                <p class="h3_align_center1 my-1 px-1 on-click_text bold" style="color: white"
                                   v-on:click="finishSecondTab=false">Spremeni</p>
                            </div>
                        </div>
                    </div>
                    <div class="py-1" v-if="!finishSecondTab && finishFirstTab">
                        <validation-observer ref="secondTabValidation">
                            <b-form>
                                <div>
                                    <h4 class="mb-0">Izberi dostavo</h4>
                                    <validation-provider #default="{ errors }" name="dostava" rules="required">
                                        <span :key="item.id" v-for="item in deliveries">
                                            <div class="my-1" style="border-bottom: 2px solid #4d4d4d; width: 100%;"/>
                                            <RadioField v-model="object.delivery" name="delivery" :value="item.id"
                                                        :text="item.title" 
                                                        :textBottom="item.subtext"/>
                                        </span>
                                        <small class="text-danger">{{ errors[0] }}</small>
                                    </validation-provider>
                                    <h4 class="mt-3">Izberi način plačila</h4>
                                    <validation-provider #default="{ errors }" name="način plačila" rules="required">
                                        <span :key="item.id" v-for="item in payments">
                                            <RadioField class="mt-1" v-model="object.payment" name="payment"
                                                        :value="item.id" :text="item.title"/>
                                        </span>
                                        <small class="text-danger">{{ errors[0] }}</small>
                                    </validation-provider>
                                    <div v-if="object.payment === 3" class="py-1" style="padding-left: 30px; padding-right: 30px;">
                                        <div v-if="invesmentData">
                                            Znesek naročila bo dodan na vaš račun/razdelilnik. Možno je tudi plačilo na {{invesmentData.min_installment}}-{{invesmentData.max_installment}} obrokov. Najnižji znesek obroka je {{Number(invesmentData.price/100).toFixed(0)}}€ evrov.
                                        </div>
                                        <div class="mt-1">
                                            Spodaj izberite na koliko obrokov želite razdeliti naročilo.
                                        </div>
                                        <div class="mt-1">
                                            <validation-provider #default="{ errors }" name="Število obrokov" rules="required">
                                                <Select v-model="object.number_of_installments" :options="numberOfInvesments" :clearable="true" placeholder="Število obrokov"></Select>
                                                <small class="text-danger">{{ errors[0] }}</small>
                                            </validation-provider>

                                        </div>
                                    </div>
                                </div>
                                <div class="mt-2 d-flex justify-content-center">
                                    <rounded-button font-size="18px" padding="0.8rem 6rem" class="button-mobile" @click="validationSecondTab">Naslednji
                                        korak
                                    </rounded-button>
                                </div>
                            </b-form>
                        </validation-observer>
                    </div>

                    <!--third tab-->
                    <div class="mt-1" v-bind:class="{'finish' : finishThirdTab, 'show-collapse' : finishSecondTab}">
                        <div style="background-color: #7da5d7; border: none" class="w-100">
                            <h3 class="bold" style="color: white; padding: 0.5rem">3. Potrditev naročila</h3>
                        </div>
                    </div>
                    <div v-if="!finishThirdTab && finishFirstTab && finishSecondTab">
                        <validation-observer ref="thirdTabValidation">
                            <b-form>
                                <div>
                                    <b-row>
                                        <b-col align="left">
                                            <h4>Način dostave:</h4>
                                        </b-col>
                                        <b-col align="right">
                                            <h4>{{ remappedShippingMethod }}</h4>
                                        </b-col>
                                    </b-row>
                                    <b-row>
                                        <b-col align="left">
                                            <h4>Način plačila:</h4>
                                        </b-col>
                                        <b-col align="right">
                                            <h4>{{ remappedPaymentMethod }}</h4>
                                        </b-col>
                                    </b-row>
                                    <b-row>
                                        <b-col align="left">
                                            <h4>Dostava:</h4>
                                        </b-col>
                                        <b-col align="right">
                                            <h4>{{ $convertPriceIntegerToFloat(shippingPrice) }} €</h4>
                                        </b-col>
                                    </b-row>
                                    <b-row>
                                        <div class="my-1 mx-1" style="border-bottom: 2px solid #4d4d4d; width: 100%;"/>
                                    </b-row>
                                    <b-row>
                                        <b-col align="left">
                                            <div class="d-flex align-items-end h-100">
                                                <h4>Skupaj:</h4>
                                            </div>
                                        </b-col>
                                        <b-col align="right">
                                            <h4 style="font-size: 26px" class="bold">
                                                {{ $convertPriceIntegerToFloat(totalPrice) }} €</h4>
                                        </b-col>
                                    </b-row>
                                    <div class="mt-2">
                                        <div v-if="!object.terms" class="mb-2" style="background-color: #FF4307; position: relative">
                                            <p class="text-white" style="padding: 5px;">Pred nakupom se moraš strinjati s
                                                splošnimi pogoji eSoseska.si.</p>
                                            <div class="arrow-down-pogoji"/>
                                        </div>
                                        <validation-provider #default="{ errors }" name="pogoji"
                                                             :rules="{ required: { allowFalse: false } }">
                                            <div class="">
                                                <CheckboxField name="terms" v-model="object.terms" class=""/>
                                                <p class="on-click_text pl-3" style="font-size: 18px; margin-top: 0.2rem"><span @click="object.terms = !object.terms">Strinjam se s </span><span @click="ModalShowTermsAndConditions = true" style="color: #7da5d7;">pogoji</span><span @click="object.terms = !object.terms"> uporabe eSoseska.</span></p>
                                            </div>
                                            <small class="text-danger">{{ errors[0] }}</small>
                                        </validation-provider>
                                    </div>
                                </div>
                                <div style="display: flex; justify-content: center">
                                    <rounded-button font-size="18px" padding="0.8rem 4rem" style="width: 20rem" class="button-mobile mt-2" @click="validationThirdTab">Oddaj naročilo</rounded-button>
                                </div>
                            </b-form>
                        </validation-observer>
                    </div>

                </b-col>
                <b-col lg="6" cols="12" v-else>
                    <div v-if="displayedLoginRegisterComponent === 'login'">

                        <div class="d-flex flex-column w-100 h-100 align-items-center">

                            <h3 class="mt-1">Za nadaljevanje nakupa se prosim prijavite.</h3>
                            <login-component class="w-100" @on-login-successful="onLoginSuccessful"/>

                            <div class="hr"/>
                            <h4 class="mt-1">Še nimaš računa?</h4>
                            <rounded-button font-size="18px" padding="0.8rem 6rem" @click="displayedLoginRegisterComponent = 'register'">REGISTRIRAJ SE</rounded-button>
                        </div>
                    </div>

                    <div v-else-if="displayedLoginRegisterComponent === 'register'">

                        <div class="d-flex flex-column w-100 h-100 align-items-center">
                        
                            <h3 class="mt-1">Registracija je hitra in enostavna</h3>

                            <register-component class="w-100" @on-register-successful="onRegisterSuccessful"/>
                            <div class="hr"/>
                            <h4 class="mt-1">Si že registran?</h4>
                            <b-button class="register-button" @click="displayedLoginRegisterComponent = 'login'">PRIJAVI SE</b-button>
                        </div>
                    </div>
                </b-col>


                <b-col lg="6" cols="12" class="mt-1 mt-lg-0">
                    <div class="px-2">
                        <b-row align-h="start" class="">
                            <b-col cols="3.5" align-self="center">
                                <h3 style="font-weight: bolder; padding-top: 0.4rem; padding-left: 0.6rem">Tvoja košarica</h3>
                            </b-col>
                            <b-col cols="5" align-self="center">
                                <b-button class="m-0" style="border: 2px solid black; padding: 0.5rem 1.5rem 0.5rem 1.5rem" squared variant="grey"
                                          @click="active = 0">Uredi vsebino
                                </b-button>
                            </b-col>
                        </b-row>

                        <DisplayWarningInShopingCart :invalidItems="validMap"/>

                        <div>
                            <div class="px-1" v-for="item in itemsToDisplay" :key="item.name">
                                <BasketItem :item="item"></BasketItem>
                            </div>
                        </div>
                        <div>
                            <b-row class="my-1">
                                <b-col align="left">
                                    <h3 class="mb-0" style="font-size: 18px;">Poštnina:</h3>
                                </b-col>
                                <b-col align="right">
                                    <h3 class="mb-0 bold">{{ $convertPriceIntegerToFloat(shippingPrice) }} €</h3>
                                </b-col>
                            </b-row>
                            <b-row class="my-1">
                                <b-col align="left">
                                    <h3 class="mb-0" style="font-size: 18px;">Skupaj boste porabili:</h3>
                                </b-col>
                                <b-col align="right">
                                    <h3 style="color: #009FD4;" class="">
                                        {{ number_of_coupons }} {{coupons_text}}
                                    </h3>
                                </b-col>
                            </b-row>
                            <div class="hr"/>
                            <b-row class="my-1">
                                <b-col align="left">
                                    <div class="d-flex align-items-end h-100">
                                        <h1 class="mb-0" style="font-size: 18px; font-weight: bold">Total:</h1>
                                    </div>
                                </b-col>
                                <b-col align="right">
                                    <h1 class="mb-0 bold" style="font-size: 38px; white-space: nowrap;">
                                        {{ $convertPriceIntegerToFloat(totalPrice) }} €</h1>
                                </b-col>
                            </b-row>
                        </div>
                    </div>
                </b-col>
            </b-row>
            <h1 class="text-center mt-4 bold">Drugi so kupili...</h1>
            <swiper :swiper-data="swiperData" :pagination="true" type="sale"/>
            <second-address ref="deliveryAddressForm" @close="ModalShowSecondaryAddress=false" :invoice-address="invoiceAddress" :modal-show="ModalShowSecondaryAddress" v-on:update-delivery-address="changeDeliveryAddress" v-on:update-delivery-address-silent="changeDeliveryAddressToPrimary"/>
            <modal-terms-and-conditions :modal-show="ModalShowTermsAndConditions" @close="ModalShowTermsAndConditions=false"/>
        </div>

        <div v-else>
            <end-order></end-order>
        </div>
    </div>
</template>

<script>
    import CheckboxField from '../../Components/CheckboxField'
    import RadioField from '../../Components/RadioField'
    import Swiper from '../../Components/Swiper'
    import Select from '../../Components/Select'
    import BasketItem from '../../Components/BasketItemSecond'
    import {ValidationProvider, ValidationObserver} from 'vee-validate'
    import {required, email} from '@validations'
    import {BRow, BCol, BButton, BForm} from 'bootstrap-vue'
    import DisplayWarningInShopingCart from './Components/DisplayWarningInShopingCart.vue'
    import DisplayAddressCard from '@/views/Pages/ShoppingCard/Components/DisplayAddressCard'
    import SecondAddress from '@/views/Pages/ShoppingCard/Components/SecondAddress'
    import RoundedButton from '@/views/Components/RoundedButton'
    import ModalTermsAndConditions from '@/views/Pages/BusinessConditions/ModalBusinessConditions'
    import EndOrder from './Components/EndOrder.vue'
    import AddPrimaryAdressForm from '@/views/Pages/ShoppingCard/Components/AddPrimaryAdressForm'

    //const CompanyForm = () => import('./Components/CompanyForm.vue')
    const LoginComponent = () => import('@/views/Pages/LoginRegister/components/LoginComponent.vue')
    const RegisterComponent = () => import('@/views/Pages/LoginRegister/components/RegisterComponent.vue')

    export default {
        components: {
            Select,
            CheckboxField,
            RadioField,
            Swiper,
            BasketItem,
            ValidationProvider,
            ValidationObserver,
            BRow,
            BCol,
            EndOrder,
            BButton,
            BForm,
            LoginComponent,
            DisplayWarningInShopingCart,
            RegisterComponent,
            DisplayAddressCard,
            SecondAddress,
            RoundedButton,
            ModalTermsAndConditions,
            AddPrimaryAdressForm
        },
        model: {
            prop: 'value',
            event: 'change'
        },
        props: {
            value: {
                default: null
            },
            items: {
                type: Array
            },
            itemsToDisplay: {
                type: Array
            },
            swiperData: {
                type: Array
            },
            shippingPrice: {
                type: Number
            }
        },
        data() {
            return {
                coupons_text: '',
                number_of_coupons: null,
                numberOfInvesments:[],
                invesmentData: null,
                addPrimaryAdress: false,
                hasPrimaryAdress: false,
                finishFirstTab: false,
                finishSecondTab: false,
                finishThirdTab: false,
                showEndOrderMessage: false,
                object: {
                    number_of_installments: null,
                    isDeliveryAddressDifferentThanInvoice: false,
                    toCompany: false,
                    delivery: '',
                    payment: '',
                    terms: false
                },
                user: {
                    name: '',
                    surname: '',
                    street: '',
                    street2: '',
                    post: '',
                    city: '',
                    email: '',
                    telephone_number: '',
                    notes: ''
                },
                deliveryAddress: {street: '', street2: '', post: '', city: '', email: '', telephone_number: ''},
                invoiceAddress: {street: '', street2: '', post: '', city: '', email: '', telephone_number: ''},
                deliveries: [
                    /*{id: 0, title: 'Osebni prevzem'},*/
                    {
                        id: 1,
                        title: 'Dostava po pošti',
                        date: 'Datum dostave od 31. maja',
                        subtext: 'Dostava pošiljke po pošti na vaš naslov. Takoj, ko bo pošiljka predana dostavni službi, vas o tem obvestimo preko e-pošte.'
                    }
                    /*{id: 2, title: 'Hitra pošta'}*/
                ],

                company_data: {naziv: '', maticna_stevilka: '', id_ddv: ''},

                // TODO - add payments that BBIS supports
                payments: [
                    {id: 1, title: 'Plačilo preko spleta (Visa, Mastercard, American Express)'},
                    {id: 2, title: 'Plačilo preko upravnika'}
                    /*{id: 3, title: 'Po povzetju'}*/
                ],
                //swiperData: [],
                required,
                email,
                isLoggedIn: false,

                displayedLoginRegisterComponent: 'login',
                validMap: null,

                ModalShowSecondaryAddress: false,
                ModalShowTermsAndConditions: false,
                installmentPaymentPrice: 0
            }
        },
        computed: {
            totalPrice() {
                const totalPrice = this.$store.getters['shoppingCart/totalPrice'] || 0
                return totalPrice + this.shippingPrice
            },
            remappedShippingMethod() {
                return this.deliveries.find(d => d.id === this.object.delivery).title || '/'
            },
            remappedPaymentMethod() {
                return this.payments.find(d => d.id === this.object.payment).title || '/'
            },
            active: {
                get() {
                    return this.value
                },
                set(value) {
                    this.$emit('change', value)
                }
            }
        },
        methods: {
            async savePrimaryAddress(new_adress) {
                try {
                    const payload = {
                        primary_address:{
                            street: new_adress.street,
                            street2: new_adress.street2,
                            post: new_adress.post,
                            city: new_adress.city
                        },
                        email: this.invoiceAddress.email,
                        telephone_number: this.invoiceAddress.telephone_number,
                        name: this.invoiceAddress.name,
                        surname: this.invoiceAddress.surname
                    }
                    await this.$http.patch('/api/user/v1/', payload)

                    await this.fillFormWithUserData()

                    this.addPrimaryAdress = false
                    this.$printSuccess('Naslov uspešno dodan.')

                } catch (err) {
                    this.$printError('Prislo je do napake pri dodajanju naslova.')
                }
                    
            },
            setPrimaryAdress() {
                this.addPrimaryAdress = true
            },
            changeDeliveryAddress(val) {
                this.deliveryAddress = val
                if (val === this.invoiceAddress) {
                    this.object.isDeliveryAddressDifferentThanInvoice = false
                } else {
                    this.object.isDeliveryAddressDifferentThanInvoice = true
                }
                this.ModalShowSecondaryAddress = false
            },
            changeDeliveryAddressToPrimary() {
                this.deliveryAddress = this.invoiceAddress
                this.object.isDeliveryAddressDifferentThanInvoice = false
            },
            async onLoginSuccessful() {
                this.$emit('show-loader', true)
                try {
                    await this.fillFormWithUserData()
                    await this.$store.dispatch('shoppingCart/getUserShoppingCart')
                    await this.$emit('get-shipping-price')
                    this.isLoggedIn = true
                } catch (error) {
                    this.$printError(`Pri nalaganju izdelkov je prišlo do napake!\n${error.message}`)
                } finally {
                    this.$emit('show-loader', false)
                }
            },
            onRegisterSuccessful() {
                this.displayedLoginRegisterComponent = 'login'
            },
            async validationFirstTab() {
                if (this.isDeliveryAddressDifferentThanInvoice) {
                    const resDeliveryAddressValidation = await this.$refs.deliveryAddressForm.validateForm()
                    if (!resDeliveryAddressValidation) return
                }

                this.finishFirstTab = true
            },
            validationSecondTab() {
                this.$refs.secondTabValidation.validate().then((res) => {
                    if (res) {
                        this.finishSecondTab = true
                    }
                })
            },
            async validationThirdTab() {
                const valid = await this.$refs.thirdTabValidation.validate()
                if (!valid) return

                this.finishThirdTab = true
                await this.finishOrder()
            },
            async loadData() {
                try {
                    await this.loadInstallmentPaymentPrice()
                    await this.loadNumberOfCoupons()
                } catch (error) {
                    this.$printError(`Prišlo je do napake pri nalaganju podatkov\n${error.message}`)
                }
            },
            async finishOrder() {
                this.$emit('show-loader', true)

                try {
                    let delivery_address = {}
                    if (this.object.isDeliveryAddressDifferentThanInvoice) {
                        delivery_address = this.deliveryAddress
                    } else {
                        delivery_address = this.invoiceAddress
                    }

                    const tmpItems = []
                    this.items.map(item => {
                        const itemPayload = {
                            id: item.id,
                            quantity: item.quantity,
                            promotional_price: item.promotional_price
                        }
                        tmpItems.push(itemPayload)
                    })

                    const payload = {
                        number_of_installments: Number(this.object.number_of_installments),
                        footnote: this.user.notes,
                        shipping_type: this.object.delivery,
                        payment_type: this.object.payment,
                        delivery_address,
                        invoice_address: this.invoiceAddress,
                        items: tmpItems
                    }

                    const response = await this.$http.put('/api/user/v1/web_shop/order/create', payload)

                    await this.$store.dispatch('shoppingCart/getUserShoppingCart')
                    this.$printSuccess('Naročilo je bilo oddano')

                    if (response.data.payment_type === 1) await this.$router.push({name: 'pay-with-card', params: {order_id: response.data.id}})
                    // else await this.$router.push({name: 'my-orders'})

                    this.showEndOrderMessage = true
                } catch (error) {
                    if (error && error.response && error.response.data && error.response.data.message) {
                        this.$printError(`${error.response.data.message}`)
                        this.finishThirdTab = false
                    } else {
                        this.$printError('Prišlo je do napake pri oddajanju naročila')
                        this.finishThirdTab = false
                    }

                } finally {
                    this.$emit('show-loader', false)
                }
            },
            addInstallmentToArray(installments) {

                this.numberOfInvesments = []

                //Razdelimo znesek kosarice, da dobimo stevilko obrokov
                let max_numInvesments = parseInt(this.totalPrice / installments.price)

                //preverimo ce je stevilo obrokov, večje kot je dovoljeno
                if (max_numInvesments > installments.max_installment) {
                    max_numInvesments = installments.max_installment
                }

                for (let i = 2; i <= max_numInvesments; i++) {
                    this.numberOfInvesments.push(i)
                }

            },
            async loadInstallmentPaymentPrice() {
                try {
                    const response = await this.$http.get('/api/user/v1/web_shop/cart/installment_payment')
                    this.installmentPaymentPrice = response.data.price
                    this.invesmentData = response.data

                    if (response.data.price > 0 && this.totalPrice > response.data.price * 2) {
                        this.payments.push({id: 3, title: 'Plačilo preko upravnika na obroke'})
                    }

                    this.addInstallmentToArray(response.data)

                } catch (err) {
                    this.$printError('Prsilo je do napake pri nalaganju podatkov')
                }
                
            },
            async fillFormWithUserData() {

                this.hasPrimaryAdress = false

                try {
                    const res = await this.$http.get('/api/user/v1/')
                    if (res.data) {
                        this.invoiceAddress.name = res.data.name
                        this.invoiceAddress.surname = res.data.surname
                        this.invoiceAddress.email = res.data.email
                        this.invoiceAddress.telephone_number = res.data.telephone_number

                        if (res.data.primary_address) {
                            this.invoiceAddress.post = res.data.primary_address.post
                            this.invoiceAddress.city = res.data.primary_address.city
                            this.invoiceAddress.street = res.data.primary_address.street
                            this.invoiceAddress.street2 = res.data.primary_address.street2

                            this.hasPrimaryAdress = true
                        }
                    }

                    if (this.invoiceAddress.post === '' || this.invoiceAddress.city === '' || this.invoiceAddress.street === '') {
                        this.hasPrimaryAdress = false
                    }

                } catch (err) {
                    this.$printError('Pri pridobivanju podatkov o uporabniku je prislo do napake.')
                }


            },
            async loadNumberOfCoupons() {
                
                try {
                    const res = await this.$http.get('/api/user/v1/promotions/number_of_coupons')

                    if (res.data) {
                        this.number_of_coupons = res.data

                        if (this.number_of_coupons === 1) {
                            this.coupons_text = 'kupon'
                        } else if (this.number_of_coupons === 2) {
                            this.coupons_text = 'kupona'
                        } else if (this.number_of_coupons === 3 || this.number_of_coupons === 4) {
                            this.coupons_text = 'kupone'
                        } else if (this.number_of_coupons >= 5) {
                            this.coupons_text = 'kuponov'
                        }

                    }
                } catch (error) {
                    this.$printError('Pri nalaganju izdelkov je prišlo do napake!')
                }
            }
        },
        created() {
            if (!this.itemsToDisplay || this.itemsToDisplay.length === 0) this.$router.replace('/')
            this.fillFormWithUserData()
            this.isLoggedIn = this.$store.state.user.loggedIn

        },
        async mounted() {
            this.$scrollToTop()
            await this.loadData()

        }
    }
</script>

<style scoped>

.arrow-down-pogoji{
    position: absolute; 
    top: calc(100% - 16px); 
    left: 0; 
    width: 0; 
    height: 0; 
    border-top: 15px solid transparent;
    border-bottom: 15px solid transparent;
    border-left: 15px solid #FF4307;
    /* transform: rotate(-45deg); */
    content: '';
}

@media only screen and (max-width: 992px) {
    .button-mobile{
        width: 100% !important;
    }
}

.bold {
    font-weight: bold;
}

.hr {
    border-bottom: 2px solid #4d4d4d;
    width: 100%;
}

.shoping-button {
    background-color: #7da5d7;
    border: none;
    font-size: 18px;
    padding: 0.786rem 3rem;
}

.finish {
    background-color: #7da5d7 !important;
    color: white !important;
    border: none !important;
}

.show-collapse {
}

.input::placeholder {
    color: black !important;
}

.register-button {
    background-color: #7da5d7;
    font-weight: bold;
    border: none;
}


.h3_align_center {
    line-height: 1;
    text-align: center;
    margin: 0px 0 0;
    padding-top: 0px;
    padding-bottom: 0px;
}

.on-click_text:hover {
    cursor: pointer;
}
</style>
