<template>
    <div>
        <BModal v-model="isModalVisible" centered ref="ConfirmActionModal" hide-header hide-footer @hide="$emit('close')" @close="$emit('close')">
            <div class="w-100 d-flex justify-content-center" >
                <h3 style="font-weight: bold;">{{title}}</h3>
            </div>
            <div>
                <validation-observer ref="addressFormValidation">
                    <validation-provider #default="{ errors }" name="Naslov 1" rules="required|min:2|max:50">
                        <InputField :profile="true" label="Naslov 1" class="mt-1 mb-1" v-model="address.street"/>
                        <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                    <validation-provider #default="{ errors }" name="Naslov 2" rules="">
                        <InputField :profile="true" label="Naslov 2" class="my-1" v-model="address.street2"/>
                        <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                    <b-row class="mb-1">
                        <b-col cols="6">
                            <validation-provider class="" #default="{ errors }" name="Mesto" rules="required|min:2|max:35">
                                <InputField :profile="true" label="Mesto" class=" -100" v-model="address.city"/>
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-col>
                        <b-col cols="6">
                            <validation-provider class="" #default="{ errors }" name="Postna številka" rules="required|min:4|max:4">
                                <InputField :profile="true" label="Poštna številka" class="w-100" v-model="address.post"/>
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-col>
                    </b-row>
                </validation-observer>
            </div>
            <div class="d-flex justify-content-center mt-2">
                <rounded-button font-size="18px" padding="0.5rem 4rem" variant="danger" class="mx-1" @click="$emit('close')">Prekliči</rounded-button>
                <rounded-button font-size="18px" padding="0.5rem 4rem" class="mr-1" @click="checkIfAdressIsValid">Potrdi</rounded-button>
            </div>
        </BModal>
    </div>
</template>

<script>
    import { BModal, BRow, BCol} from 'bootstrap-vue'
    import RoundedButton from '@/views/Components/RoundedButton'
    import InputField from '@/views/Components/InputField'
    import { ValidationProvider, ValidationObserver } from 'vee-validate'
    import { required} from '@validations'


    export default {
        components:{
            InputField,
            ValidationProvider,
            ValidationObserver,
            BRow,
            BCol,
            BModal,
            RoundedButton
        },
        props:{
            title: {
                type: String
            },
            modalShow:{
                type: Boolean,
                default: false
            }
        },
        data() {
            return {
                address: {
                    street: '',
                    street2:'',
                    post: '',
                    city: ''
                },
                isModalVisible: false,
                required
            }
        },
        methods:{
            async checkIfAdressIsValid() {
                const res = await this.$refs.addressFormValidation.validate()

                if (res) {
                    this.$emit('confirm-action', this.address)
                }
            }
        },
        watch:{
            'modalShow'(val) {
                this.isModalVisible = val
            }
        }
    }
</script>
<style scoped>

</style>