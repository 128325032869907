<template>
    <div>
        <b-row>
            <b-col lg="9" class="pr-lg-5">
                <DisplayWarningInShopingCart :invalidItems="validMap" class="my-1"/>
                <template v-if="!itemsToDisplay || itemsToDisplay.length === 0">
                    <div class="d-flex flex-column align-items-center justify-content-center w-100 h-100 mb-2">
                        <fa icon="shopping-cart" size="5x" class="shopping-cart-icon mb-2" />
                        <h1>Vaša košarica je prazna</h1>
                    </div>
                </template>
                <template v-else>
                    <div v-for="(item, id) in itemsToDisplay" :key="'basket_item_' + String(id) + item.id">
                        <BasketItem v-on:refreshShoppingCart="reloadData" @on-item-removed-from-shopping-cart="onItemRemovedFromShoppingCart" :item="item"></BasketItem>
                    </div>
                </template>
            </b-col>
            <b-col lg="3" cols="12">
                <div class="p-1 mt-1" style="background-color: #E6E6E6;">
                    <div class="">
                        <h3>Skupni znesek košarice</h3>
                        <h3 style="font-size: 36px;" class="bold pt-3">{{ $convertPriceIntegerToFloat(totalPrice) }} €</h3>
                    </div>
                     
                </div>
                <div>
                    <div v-if="number_of_coupons > 0 && number_of_coupons > number_of_my_coupons" class="d-flex justify-content-center text-center">
                        <div class="mt-1" style="font-weight:bold; color: red;">
                            Za nadaljevanje naročila nimate dovolj kuponov.
                        </div>
                    </div>
                    <b-button v-if="emailConfirmed && loggedIn" pill class="mt-1 shop-button w-100"
                              :disabled="!itemsToDisplay || itemsToDisplay.length === 0 || (number_of_coupons > 0 && number_of_coupons > number_of_my_coupons)"
                              @click="active = 1" size="lg">NA BLAGAJNO
                    </b-button>
                    <div v-else-if="!loggedIn" class="d-flex justify-content-center text-center">
                        <div class="my-1" style="font-weight:bold; color: red;">
                            Za nadaljevnaje oddaje naročila, se potrebujete prijaviti.
                        </div>
                    </div>
                    <div v-else class="d-flex justify-content-center text-center">
                        <div class="my-1" style="font-weight:bold; color: red;">
                            Za nadaljevnaje oddaje naročila, potrebujete potrditi vaš email naslov.
                        </div>
                    </div>
                </div>
            <!--                    <div>
                        <b-row class="mt-1" v-for="(item, index) in accessories" :key="index">
                            <b-col cols="2" lg="2">
                                <h4>
                                    <img style="color: #7da5d7; height: 2rem" :src="item.icon"/>
                                </h4>
                            </b-col>
                            <b-col cols="7" lg="7">
                                <h4 style="font-size: 16px">{{ item.text }} <span
                                    style="font-weight: bold;font-size: 16px">{{ item.subtext }}</span></h4>
                            </b-col>
                            <b-col cols="3" lg="3">
                                <router-link to="#">
                                    <h4 style="font-size: 14px; font-weight: bolder; color: #7da5d7;">
                                        > več
                                    </h4>
                                </router-link>
                            </b-col>
                        </b-row>
                    </div>-->
            </b-col>
        </b-row>
        <h1 class="text-center mt-4 bold">Morda vas zanima tudi...</h1>
        <swiper :swiper-data="swiperData" :pagination="true" type="sale"/>
    </div>
</template>

<script>
    import Swiper from '../../Components/Swiper'
    import BasketItem from '../../Components/BasketItem'
    import {BRow, BCol, BButton} from 'bootstrap-vue'
    import DisplayWarningInShopingCart from './Components/DisplayWarningInShopingCart.vue'
    export default {
        components: {
            Swiper,
            BasketItem,
            BRow,
            BCol,
            BButton,
            DisplayWarningInShopingCart
        },
        model: {
            prop: 'value',
            event: 'change'
        },
        props: {
            validMap: {
                type: Object
            },
            value: {
                default: null
            },
            itemsToDisplay: {
                type: Array
            },
            swiperData: {
                type: Array
            },
            shippingPrice: {
                type: Number
            }
        },
        data() {
            return {
                number_of_coupons: 0,
                number_of_my_coupons: 0,
                accessories: [
                    {
                        icon: require('@/assets/images/pages/shoppingCard/truck.svg'),
                        text: 'Datum dostave Od',
                        subtext: '31.5.'
                    },
                    {icon: require('@/assets/images/pages/shoppingCard/card.svg'), text: 'Način plačila', subtext: ''}
                ],
                categoriesInCart: []
            }
        },
        methods: {
            reloadData() {
                this.$emit('refreshShoppingCart')
            },
            async onItemRemovedFromShoppingCart() {
                await this.$store.dispatch('shoppingCart/getUserShoppingCart')
                await this.$emit('get-shipping-price')
            },
            async loadNumberOfCoupons() {
                
                try {
                    const res = await this.$http.get('/api/user/v1/promotions/number_of_coupons')
                    if (res.data) {
                        this.number_of_coupons = res.data

                        const res_points_total = await this.$http.get('/api/user/v1/points/get_collected_points')
                        const points = res_points_total.data
                        if (points) {
                            this.number_of_my_coupons = parseInt(points / 300)
                        }
                    }

                } catch (error) {
                    this.$printError('Pri nalaganju izdelkov je prišlo do napake!')
                }
            }
        },
        computed: {
            loggedIn() {
                return this.$store.getters['user/loggedIn']
            },
            emailConfirmed() {
                return this.$store.getters['user/emailConfirmed']
            },
            totalPrice() {
                const totalPrice = this.$store.getters['shoppingCart/totalPrice'] || 0
                return totalPrice
            },
            active: {
                get() {
                    return this.value
                },
                set(value) {
                    this.$emit('change', value)
                }
            }
        },
        mounted() {
            this.loadNumberOfCoupons()
        }
    }
</script>

<style scoped>
.bold {
    font-weight: bold;
}

.hr {
    border-top: 2px solid #E6E6E6;
    width: 100%;
}

#input .form-control, #input .input-group-append button {
    border-radius: 0 !important;
}

.input::placeholder {
    color: black !important;
}

.shop-button {
    border: none;
    font-size: 22px;
}

.shopping-cart-icon {
    color: #e6e6e6;
}
</style>
