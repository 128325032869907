<template>
    <BModal body-class="pt-0" size="xl" hide-footer v-model="isModalVisible" @hide="$emit('close')" @close="$emit('close')">

        <template #modal-header="{ close }">
            <h3 style="font-size: 20px; font-weight: bold">Ostali naslovi</h3>
            <b-button size="sm" class="button-modal-close" @click="close()">
                <p class="m-0">
                    X
                </p>
            </b-button>
        </template>
        <div class="d-flex justify-content-center mb-2">
            <rounded-button font-size="18px" padding="0.8rem 8rem" @click="ModalNewAddressShow=true">Dodaj nov naslov</rounded-button>
        </div>
        <b-row>
            <b-col cols="12" lg="6" v-for="(address,index) in secondary_addresses" :key="`sec_add_${index}`">
                <div>
                    <div class="hr" style="margin: 0.5rem 0rem 1rem 0rem"/>
                    <AddressCard class="on-click_text" style="height: 75%" v-on:refresh-address-delete="checkForDeletedAddress" v-on:refresh-address="refreshData" v-on:selected-address="changeDeliveryAddress" :address="address" :shoppingCart="true"/> <!--v-bind:class=" selectedIndex === index ? 'selectedAddress' : '' "-->
                    <div class="hr" style="margin: 1rem 0rem 0.5rem 0rem"/>
                </div>

            </b-col>
        </b-row>
        <ModalAddress :profile="true" :address-object="{name:invoiceAddress.name, surname:invoiceAddress.surname}" :modal-show="ModalNewAddressShow" v-on:refresh="refreshData" @close="ModalNewAddressShow=false"/>
    </BModal>
</template>

<script>
    import {BModal, BRow, BCol, BButton} from 'bootstrap-vue'
    import AddressCard from '@/views/Components/AddressCard'
    import ModalAddress from '@/views/Components/ModalAddress'
    import RoundedButton from '@/views/Components/RoundedButton'

    export default {
        
        components:{
            RoundedButton,
            AddressCard,
            ModalAddress,
            BModal,
            BRow,
            BCol,
            BButton
        },

        props: {
            modalShow: {
                type: Boolean,
                default: false
            },
            invoiceAddress: {
                type: Object,
                required: true
            }
        },

        data() {
            return {
                selectedIndex: 0,
                secondary_addresses: [],
                ModalNewAddressShow: false,
                isModalVisible: false
            }
        },

        methods:{
            loadData() {
                const thisIns = this

                thisIns.$http.get('/api/user/v1/address/')
                    .then(res => {
                        thisIns.secondary_addresses = res.data
                        thisIns.secondary_addresses.unshift(thisIns.invoiceAddress)
                    })
                    .catch(error => {
                        thisIns.$printError(`Pri posodabljanju podatkov je prišlo do napake!\n${error.message}`)
                        thisIns.showLoader = false
                    })
            },
            changeDeliveryAddress(val) {
                const index = this.secondary_addresses.findIndex(e => e.id === val.id)
                this.selectedIndex = index
                this.$emit('update-delivery-address', val)
            },
            updateSecondaryAddress() {
                const thisIns = this
               
                thisIns.$http.get('/api/user/v1/address/')
                    .then(res => {
                        thisIns.secondary_addresses = res.data
                    })
                    .catch(error => {
                        thisIns.$printError(`Pri posodabljanju podatkov je prišlo do napake!\n${error.message}`)
                        thisIns.showLoader = false
                    })
            },
            checkForDeletedAddress(val) {
                const index = this.secondary_addresses.findIndex(e => e.id === val.id)
                if (index === this.selectedIndex) this.selectedIndex = 0
                this.loadData()
                this.ModalNewAddressShow = false
                this.$emit('update-delivery-address-silent')  //preventivno deselecta izbrisan address in ga nastavi delivery_address na primarni address
            },
            refreshData() {
                this.loadData()
                this.ModalNewAddressShow = false
            }
        },

        watch:{
            'modalShow'(val) {
                this.isModalVisible = val
            }
        },

        mounted() {
            this.loadData()
        }

    }

</script>

<style lang="scss" scoped>

    .button-modal-close{
        border: none !important;
        background: white !important;
        color: black !important;
        font-weight: bold;
        font-size: 1.6rem;
    }

    .button-modal-close:active, .button-modal-close:hover, .button-modal-close:focus{
        box-shadow: 0 8px 25px -8px black !important; ;
    }

    .selectedAddress {
        border: 2px solid #009fd4;
    }
    .hr {
        border-bottom: 2px solid #4d4d4d;
        width: 100%;
    }

</style>