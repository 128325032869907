var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('BModal',{ref:"ConfirmActionModal",attrs:{"centered":"","hide-header":"","hide-footer":""},on:{"hide":function($event){return _vm.$emit('close')},"close":function($event){return _vm.$emit('close')}},model:{value:(_vm.isModalVisible),callback:function ($$v) {_vm.isModalVisible=$$v},expression:"isModalVisible"}},[_c('div',{staticClass:"w-100 d-flex justify-content-center"},[_c('h3',{staticStyle:{"font-weight":"bold"}},[_vm._v(_vm._s(_vm.title))])]),_c('div',[_c('validation-observer',{ref:"addressFormValidation"},[_c('validation-provider',{attrs:{"name":"Naslov 1","rules":"required|min:2|max:50"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('InputField',{staticClass:"mt-1 mb-1",attrs:{"profile":true,"label":"Naslov 1"},model:{value:(_vm.address.street),callback:function ($$v) {_vm.$set(_vm.address, "street", $$v)},expression:"address.street"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])}),_c('validation-provider',{attrs:{"name":"Naslov 2","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('InputField',{staticClass:"my-1",attrs:{"profile":true,"label":"Naslov 2"},model:{value:(_vm.address.street2),callback:function ($$v) {_vm.$set(_vm.address, "street2", $$v)},expression:"address.street2"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])}),_c('b-row',{staticClass:"mb-1"},[_c('b-col',{attrs:{"cols":"6"}},[_c('validation-provider',{attrs:{"name":"Mesto","rules":"required|min:2|max:35"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('InputField',{staticClass:" -100",attrs:{"profile":true,"label":"Mesto"},model:{value:(_vm.address.city),callback:function ($$v) {_vm.$set(_vm.address, "city", $$v)},expression:"address.city"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-col',{attrs:{"cols":"6"}},[_c('validation-provider',{attrs:{"name":"Postna številka","rules":"required|min:4|max:4"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('InputField',{staticClass:"w-100",attrs:{"profile":true,"label":"Poštna številka"},model:{value:(_vm.address.post),callback:function ($$v) {_vm.$set(_vm.address, "post", $$v)},expression:"address.post"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1)],1),_c('div',{staticClass:"d-flex justify-content-center mt-2"},[_c('rounded-button',{staticClass:"mx-1",attrs:{"font-size":"18px","padding":"0.5rem 4rem","variant":"danger"},on:{"click":function($event){return _vm.$emit('close')}}},[_vm._v("Prekliči")]),_c('rounded-button',{staticClass:"mr-1",attrs:{"font-size":"18px","padding":"0.5rem 4rem"},on:{"click":_vm.checkIfAdressIsValid}},[_vm._v("Potrdi")])],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }