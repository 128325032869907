<template>
    <div style="background: #E7E6E6;" class="d-flex justify-content-center p-5">
        <div>
            <div class="d-flex justify-content-center mb-2">
                <img style="width: 100px; height: 100px" :src="require('@/assets/images/icons/smiley.svg')"/>
            </div>
            <div class="d-flex justify-content-center mb-2">
                <div class="main-title">
                    Vaše naročilo je bilo oddano!
                </div>
            </div>
            <div class="content-order-end mb-2">
                Adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                Ut enim ad minim veniam
            </div>
            <div class="d-flex justify-content-center mb-3">
                <b-button @click="redirectToWebshop">Vrni se v trgovino</b-button>
            </div>
            <div class="d-flex justify-content-center">
                <router-link :to="{ name: 'my-orders'}">
                    <div class="d-flex align-items-center">
                        <div class="round-circle-button-order-end d-flex justify-content-center mr-2 align-items-center">
                            >
                        </div>
                        Zgodovina naročil
                    </div>
                </router-link>
            </div>
        </div>
    </div>

</template>

<script>
    import { BButton } from 'bootstrap-vue'
    export default {
        components:{
            BButton
        },
        methods:{
            redirectToWebshop() {
                this.$router.push({name: 'web-shop-items-list'})
            }
        }
    }
</script>

<style scoped>
    .round-circle-button-order-end{
        border-radius: 50%;
        color: white !important;
        background: #72A5D8;
        width: 40px;
        height: 40px;
        font-size: 2rem;
    }
    .main-title{
        font-size: 2.5rem;
        color: #72A5D8;
        font-weight: bold;
        max-width: 300px;
        text-align: center;
    }
</style>