<template>
    <div class="py-1">
        <b-row class="row-margin">
            <b-col>
                <p class="zero_m" style="font-size: 18px">Ime: <span
                    class="bold">{{ address.name }} {{ address.surname }}</span></p>
            </b-col>
        </b-row>
        <b-row class="row-margin">
            <b-col>
                <p class="zero_m" style="font-size: 16px">Naslov: <span class="bold">{{ address.street }}</span></p>
            </b-col>
        </b-row>
        <b-row v-if="address.street2 !== '' && address.street2 !== undefined" class="row-margin">
            <b-col>
                <p class="zero_m" style="font-size: 16px">Naslov 2: <span class="bold">{{ address.street2 }}</span></p>
            </b-col>
        </b-row>
        <b-row class="row-margin">
            <b-col>
                <p class="zero_m" style="font-size: 16px">Poštna številka in kraj: <span class="bold">{{ address.post }} {{address.city }}</span></p>
            </b-col>
        </b-row>
        <b-row class="row-margin">
            <b-col>
                <p class="zero_m" style="font-size: 16px">Telefon: <span
                    class="bold">{{ address.telephone_number }}</span></p>
            </b-col>
        </b-row>
        <b-row class="row-margin">
            <b-col>
                <p class="zero_m" style="font-size: 16px">Email: <span class="bold">{{ address.email }}</span></p>
            </b-col>
            <b-col v-if="showAddressButton" class="d-flex align-content-center justify-content-end">
                <p class="on-click_text" @click="$emit('open-second-address-model')" style="font-size: 16px; font-weight: bold; color: #72a5d8">Vsi naslovi <feather-icon icon="ChevronRightIcon" size="20"/></p>
            </b-col>
        </b-row>
    </div>
</template>

<script>
    import {BRow, BCol} from 'bootstrap-vue'

    export default {
        components: {BRow, BCol},
        props: {
            address: {
                type: Object,
                required: true
            },
            showAddressButton: {
                type: Boolean,
                required: false,
                default: false
            }
        }
    }
</script>

<style scoped>
.bold {
    font-weight: bold;
}

.row-margin{
    margin-top: 0.2rem;
    margin-bottom: 0.2rem;
}

.zero_m {
    margin: 0rem;
}

.on-click_text:hover {
    cursor: pointer;
}
</style>