<template>
    <b-row class="my-1">
        <b-col align-self="center" lg="3" md="4" sm="4" cols="12" class="mt-1">
            <div class="d-flex justify-content-center">
                <router-link :to="{name: 'web-shop-item', params: { item_id: item.id.replace('_coupons', '')  }}">
                    <b-img v-if="item.main_picture" fluid :src="'/api/user/v1/web_shop/product_image/500_' + item.main_picture" style="max-height: 150px;"/>
                    <b-img v-else :src="require('@/assets/images/esoseska/no-image.jpg')" style="max-height: 150px;"/>
                </router-link>

            </div>
        </b-col>
        <b-col align-self="start" lg="9" md="8" sm="8" cols="12" class="mt-1 px-0">
            <div class="d-flex align-items-center justify-content-between flex-wrap">
                <div>
                    <router-link v-if="item.category.title !== ''" :to="{name: 'web-shop-category-items-list', params: { category_id: item.category.id  }}">
                        <div class="text-secondary font-normal">
                            <feather-icon icon="ChevronRightIcon" size="20"/>
                            {{ item.category.title}}
                        </div>
                    </router-link>
                </div>
                <div v-if="item.discounted" class="ml-2 font-small">
                    <del>{{ $convertPriceIntegerToFloat(item.price) }} €</del>
                </div>
            </div>
            <div class="d-flex align-items-center justify-content-md-between">
                <div>
                    <router-link :to="{name: 'web-shop-item', params: { item_id: item.id  }}">
                        <div class="font-medium" style="color: black;">
                            {{ item.description_1 }}
                        </div>
                    </router-link>
                </div>
                <!-- <div class="d-none d-md-block font-big text-right" style="margin-left: 0.4rem; white-space: nowrap;">
                    {{ $convertPriceIntegerToFloat(item.discounted ? item.price_discounted : item.price) }} €
                </div> -->
                <div v-if="item.promotional_price && promotion" class="text-right">
                    <div class="d-none d-md-block" style="margin-left: 0.4rem; white-space: nowrap; text-decoration: line-through; font-size: 12px !important; font-weight: bold;">
                        {{ $convertPriceIntegerToFloat(item.discounted ? item.price_discounted : item.price) }} €
                    </div>
                    <div class="font-big">
                        {{ $convertPriceIntegerToFloat(promotion.price) }} €
                    </div>
                </div>
                <div v-else class="d-none d-md-block font-big" style="margin-left: 0.4rem; white-space: nowrap;">
                    {{ $convertPriceIntegerToFloat(item.discounted ? item.price_discounted : item.price) }} €
                </div>
            </div>
            <div class="d-flex align-items-center justify-content-between" style="margin-top: 0.2rem">
                <div class="font-normal">
                    {{item.id.replace("_coupons", "")}}
                </div>
                <!-- <div v-if="item.points" class="font-normal">
                    TOČKE: {{item.points}}
                </div> -->
                <div class="font-normal" v-if="item.promotional_price && promotion">
                    Porabili boste <span style="color: #009FD4;">{{promotion.number_of_coupons * item.quantity}} {{ coupons_text }}</span>
                </div>
            </div>
            <div class="font-normal" style="padding-top: 0.5rem;">
                Količina: {{item.quantity}}x
            </div>
            <div class="d-md-none mb-1 font-big text-right" style="white-space: nowrap;">
                {{ $convertPriceIntegerToFloat(item.discounted ? item.price_discounted : item.price) }} €
            </div>
            <div v-if="item.options">
                <div class="d-flex align-items-center justify-content-between" v-for="option in item.options" :key="option.name" style="padding: 0.2rem 0;">
                    <div class="font-normal mr-1">+ {{option.name}}</div>
                    <div class="font-normal text-right" style="white-space: nowrap">{{$convertPriceIntegerToFloat(option.price)}} €</div>
                </div>
            </div>
        </b-col>
        <div class="hr" style="margin-top: 0.5rem;"/>
    </b-row>
</template>

<script>
    import {BRow, BCol, BImg} from 'bootstrap-vue'

    export default {
        components: {BRow, BCol, BImg},
        props: {
            item: {
                type: Object,
                required: true
            }
        },
        data() {
            return {
                promotion: null
            }
        },
        methods:{
            async loadPromotions() {
                try {
                    const res = await this.$http.get(`/api/user/v1/promotions/products/${this.item.id.replace('_coupons', '')}`)

                    if (res.data && res.data.bbis_id === this.item.id.replace('_coupons', '')) {
                        this.promotion = res.data
                    }

                    if (this.promotion && (this.promotion.number_of_coupons * this.item.quantity) === 1) {
                        this.coupons_text = 'kupon'
                    } else if (this.promotion && (this.promotion.number_of_coupons * this.item.quantity) === 2) {
                        this.coupons_text = 'kupona'
                    } else if (this.promotion && (this.promotion.number_of_coupons * this.item.quantity) <= 4) {
                        this.coupons_text = 'kupone'
                    } else if (this.promotion && (this.promotion.number_of_coupons * this.item.quantity) >= 5) {
                        this.coupons_text = 'kupone'
                    }
                  
                } catch (error) {
                    this.$printError('Pri nalaganju izdelkov je prišlo do napake!')
                }
                
            }
        },
        mounted() {
            this.loadPromotions()
        }
    }
</script>

<style scoped>
.hr {
    border-bottom: 2px solid #4d4d4d;
    width: 100%;
}
.font-small {
  font-weight: 400;
  font-size: 15px;
  line-height: 19px;
}
.font-normal {
  font-weight: 400;
  font-size: 17px;
  line-height: 23px;
}
.font-medium {
  font-weight: 700;
  font-size: 20px;
  line-height: 25px;
}
.font-big {
  font-weight: 700;
  font-size: 25px;
  line-height: 31px;
}
</style>