<template>
    <div @click="select">
        <b-row align-h="between" class="row-margin">
            <b-col>
                <p class="zero_m" style="font-size: 18px">Ime: <span
                    class="bold">{{ address.name }} {{ address.surname }}</span></p>
            </b-col>
            <b-col class="d-flex justify-content-end align-items-center">  <!--ker invoice address se pridobi iz stora nima shranjenega id iz baze in se lahko po tem loci od ostalih naslovov-->
                <div v-if="!address.id" class="rounded-pill" style="background-color: #b7d2f1; display: inline-block">
                    <p class="m-0 px-2 font-14" style="color: white; padding-top: 0.2rem; padding-bottom: 0.2rem">Primarni profil</p>
                </div>
                <div v-else>
                    <b-img @click="ModalShowConfirmAction=true" class="on-click_text p-0 m-0" style="height: 2rem; color: white" src="@/assets/images/pages/shoppingCard/remove.svg"/>
                </div>
            </b-col>
        </b-row>
        <b-row class="mt-2 row-margin">
            <b-col>
                <p class="zero_m" style="font-size: 16px">Naslov: <span class="bold">{{ address.street }}</span></p>
            </b-col>
        </b-row>
        <b-row v-if="address.street2 && address.street2 !== undefined" class="row-margin">
            <b-col>
                <p class="zero_m" style="font-size: 16px">Naslov 2: <span class="bold">{{ address.street2 }}</span></p>
            </b-col>
        </b-row>
        <b-row class="row-margin">
            <b-col>
                <p class="zero_m" style="font-size: 16px">Poštna številka in kraj: <span class="bold whole_word">{{ address.post }} {{address.city }}</span></p>
            </b-col>
        </b-row>
        <b-row class="row-margin">
            <b-col>
                <p class="zero_m" style="font-size: 16px">Telefon: <span
                    class="bold">{{ address.telephone_number }}</span></p>
            </b-col>
        </b-row>
        <b-row class="row-margin">
            <b-col>
                <p class="zero_m" style="font-size: 16px">Email: <span class="bold">{{ address.email }}</span></p>
            </b-col>
        </b-row>
        <b-row class="row-margin">
            <b-col v-if="shoppingCart" class="d-flex justify-content-end align-items-center">
                <p class="on-click_text" @click="select" style="font-weight: bold; font-size: 17px; color: #7da5d7">Izberi <feather-icon icon="ChevronRightIcon" size="20"/></p>
            </b-col>
            <b-col v-else class="d-flex justify-content-end align-items-center">
                <p class="on-click_text" @click="editAddress" style="font-weight: bold; font-size: 17px; color: #7da5d7">Uredi <feather-icon icon="ChevronRightIcon" size="20"/></p>
            </b-col>
        </b-row>
        <ModalAddress :modalShow="ModalShowModalAddress" :profile="true" :address-object="addressCpy" v-on:refresh="passUpdate" @hide="ModalShowModalAddress=false" @close="ModalShowModalAddress=false" />
        <ConfirmActionModal :modal-show="ModalShowConfirmAction" title="Potrdite izbris naslova" v-on:confirm-action="deleteAddress" @hide="ModalShowConfirmAction=false" @close="ModalShowConfirmAction=false"/>

    </div>
</template>

<script>
    import {BRow, BCol, BImg} from 'bootstrap-vue'
    import ModalAddress from '@/views/Components/ModalAddress'
    import ConfirmActionModal from './ConfirmActionModal.vue'

    export default {
        components: {
            ModalAddress,
            ConfirmActionModal,
            BRow,
            BCol,
            BImg
        },
        props: {
            address: {
                type: Object,
                default() {
                    return {
                        country: 'Slovenija'
                    }
                }
            },
            shoppingCart:{
                type: Boolean,
                default: false
            }
        },
        data() {
            return {
                addressCpy: null,
                inputObject: this.address,
                ModalShowModalAddress: false,
                ModalShowConfirmAction: false
            }
        },
        methods: {
            deleteAddress() {
                const thisIns = this
                this.$http.delete(`/api/user/v1/address/${this.address.id}`)
                    .then(() => {
                        thisIns.ModalShowConfirmAction = false
                        thisIns.$printSuccess('Naslov izbrisan!')
                        thisIns.$emit('refresh-address-delete', this.address)
                    })
                    .catch(error => {
                        thisIns.$printError(`Pri brisanju naslova je prišlo do napake!\n${error.message}`)
                    })
            },
            editAddress() {
                this.addressCpy = JSON.parse(JSON.stringify(this.address))
                this.ModalShowModalAddress = true
            },
            passUpdate() {
                this.ModalShowModalAddress = false
                this.$emit('refresh-address')
            },
            select() {
                if (this.ModalShowConfirmAction || this.ModalShowModalAddress) return
                this.$emit('selected-address', this.address)
            }
        }
    }
</script>

<style scoped>
.whole_word{
    white-space: nowrap;
}

.bold {
    font-weight: bold;
}

.row-margin{
    margin-top: 0.2rem;
    margin-bottom: 0.2rem;
}

.zero_m {
    margin: 0rem;
}

.on-click_text:hover {
    cursor: pointer;
}

font-14 {
    font-size: 14px;
}
</style>

