import { render, staticRenderFns } from "./AddPrimaryAdressForm.vue?vue&type=template&id=fe296620&scoped=true&"
import script from "./AddPrimaryAdressForm.vue?vue&type=script&lang=js&"
export * from "./AddPrimaryAdressForm.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "fe296620",
  null
  
)

export default component.exports