<template>
    <label class="check m-0 d-flex align-items-center">
        <span>
            <input type="radio" :value="value" v-model="model" :name="name">
            <span class="mark"/>
        </span>
        <span class="text w-100">
            {{text}}
            <span v-if="textRight" style="float:right; font-size:13px; line-height: 19px;">{{textRight}}</span>
            <span v-if="textBottom" style="display: block !important; margin-top: 5px; font-size:13px; line-height: 19px;">{{textBottom}}</span>
        </span>
    </label>
</template>

<script>
    export default
        {
            model: {
                prop: 'checked',
                event: 'change'
            },
            props: {
                text: {
                    type: String,
                    required: true
                },
                textRight: {
                    type: String,
                    required: false
                },
                textBottom: {
                    type: String,
                    required: false
                },
                value: {
                    default: null
                },
                type: {
                    default: null
                },
                name: {
                    type: String,
                    required: true
                },
                checked: {
                    type: [Array, Boolean, Number, String],
                    default: false
                }
            },
            computed: {
                model: {
                    get() {
                        return this.checked
                    },
                    set(value) {
                        this.$emit('change', value)
                    }
                }
            }
        }
</script>

<style scoped>
.text {
  margin-top: -1px;
  margin-left: 18px;
  font-weight: 400;
  font-size: 17px;
  line-height: 23px;
  color: black;
}
.check {
  display: inline-block;
  position: relative;
  cursor: pointer;
}
.check input {
  opacity: 0;
}
.mark {
  position: absolute;
  top: 0;
  left: 0;
  height: 17px;
  width: 17px;
  background-color: #fff;
  padding: 0px !important;
}
.mark:after {
  content: "";
  position: absolute;
  display: none;
}
.check input:checked ~ .mark:after {
  display: block;
}
.check .mark{
  width: 21px;
  height: 21px;
  border: 1px solid black;
  border-radius:50%;
  outline:none;
}
.check .mark:after {
  width: 100%;
  border: 3px solid white;
  height: 100%;
  background-color: #72A5D8;
  border-radius: 50% !important;
}
</style>