<template>
    <b-modal class="scroll-custom" size="lg" scrollable hide-footer v-model="isModalVisible" @hide="$emit('close')" @close="$emit('close')">
        <template #modal-header="{ close }">
            <h3 style="font-size: 20px; font-weight: bold">Pogoji uporabe</h3>
            <b-button size="sm" class="button-modal-close" @click="close()">
                <p class="m-0">
                    X
                </p>
            </b-button>
        </template>

        <b-tabs class="mt-0">
            <b-tab title="Splošni pogoji" active>
                <div class="row"  style="background: white;">
                    <div class="main-title">
                        Pogoji poslovanja
                    </div>
                    <div class="mt-2 mt-md-0" style="min-height: 400px;">
                        <div class="accordion" role="tablist">
                            <div class="borderBottom" v-for="(item, index) of businessConditionsBasic" :key="item.id">
                                <b-button class="button-header-legal-notice p-0 py-1" block v-b-toggle :href="'#condition_'+item.id" @click.prevent>{{item.title}} <span class="when-open">-</span><span class="when-closed">+</span></b-button>
                                <b-collapse :id="'condition_'+item.id" :visible="index === 0 ? true:false" accordion="my-accordion" role="tabpanel">
                                    <div class="html-display" v-html="item.content"/>
                                    <b-button class="button-end-legal-notice p-0 py-1" v-b-toggle :href="'#condition_'+item.id" @click.prevent>
                                        Zapri
                                        <img style="height: 17px; width:17px; margin-left: 5px;" :src="require('@/assets/images/icons/Minus.svg')">
                                    </b-button>
                                </b-collapse>
                            </div>
                        </div>
                    </div>
                </div>
            </b-tab>
            <b-tab title="Habit d.o.o." active>
                <div class="row"  style="background: white;">
                    <div class="main-title">
                        Pogoji poslovanja
                    </div>
                    <div class="mt-2 mt-md-0" style="min-height: 400px;">
                        <div class="accordion" role="tablist">
                            <div class="borderBottom" v-for="(item, index) of businessConditionsHabit" :key="item.id">
                                <b-button class="button-header-legal-notice p-0 py-1" block v-b-toggle :href="'#condition_'+item.id" @click.prevent>{{item.title}} <span class="when-open">-</span><span class="when-closed">+</span></b-button>
                                <b-collapse :id="'condition_'+item.id" :visible="index === 0 ? true:false" accordion="my-accordion" role="tabpanel">
                                    <div class="html-display" v-html="item.content"/>
                                    <b-button class="button-end-legal-notice p-0 py-1" v-b-toggle :href="'#condition_'+item.id" @click.prevent>
                                        Zapri
                                        <img style="height: 17px; width:17px; margin-left: 5px;" :src="require('@/assets/images/icons/Minus.svg')">
                                    </b-button>
                                </b-collapse>
                            </div>
                        </div>
                    </div>
                </div>
            </b-tab>
            <b-tab title="Staninvest d.o.o.">
                <div class="row"  style="background: white;">
                    <div class="main-title">
                        Pogoji poslovanja
                    </div>
                    <div class="mt-2 mt-md-0" style="min-height: 400px;">
                        <div class="accordion" role="tablist">
                            <div class="borderBottom" v-for="(item, index) of businessConditionsStanInvest" :key="item.id">
                                <b-button class="button-header-legal-notice p-0 py-1" block v-b-toggle :href="'#condition_'+item.id" @click.prevent>{{item.title}} <span class="when-open">-</span><span class="when-closed">+</span></b-button>
                                <b-collapse :id="'condition_'+item.id" :visible="index === 0 ? true:false" accordion="my-accordion" role="tabpanel">
                                    <div class="html-display" v-html="item.content"/>
                                    <b-button class="button-end-legal-notice p-0 py-1" v-b-toggle :href="'#condition_'+item.id" @click.prevent>
                                        Zapri
                                        <img style="height: 17px; width:17px; margin-left: 5px;" :src="require('@/assets/images/icons/Minus.svg')">
                                    </b-button>
                                </b-collapse>
                            </div>
                        </div>
                    </div>
                </div>
            </b-tab>
        </b-tabs>
        <b-row class="mt-2">
            <b-col class="d-flex justify-content-center">
                <rounded-button class="w-50"  font-size="18px" padding="0.8rem 4rem" @click="$emit('close')">Zapri</rounded-button>
            </b-col>
        </b-row>
    </b-modal>
</template>

<script>
    import {BModal, BRow, BCol, BButton, BTab, BTabs} from 'bootstrap-vue'
    import RoundedButton from '@/views/Components/RoundedButton'

    export default {
        components: {
            BModal,
            BButton,
            BCol,
            BRow,
            RoundedButton,
            BTab, 
            BTabs
        },

        props: {
            modalShow: {
                type: Boolean,
                default: false
            }
        },
        data() {
            return {
                isModalVisible: false,
                businessConditionsBasic: [],
                businessConditionsHabit: [],
                businessConditionsStanInvest: []
            }
        },
        methods: {
            async loadBothBusinessConditions() {
                try {
                    const responseHabit = await this.$http.get('/api/user/v1/business_condition/habit')
                    this.businessConditionsHabit = responseHabit.data

                    const responseStanInvest = await this.$http.get('/api/user/v1/business_condition/stanInvest')
                    this.businessConditionsStanInvest = responseStanInvest.data

                    const responseBasic = await this.$http.get('/api/user/v1/business_condition/basic')
                    this.businessConditionsBasic = responseBasic.data
                } catch (err) {
                    this.$printError(err)
                }
            }
        },
        mounted() {
            this.loadBothBusinessConditions()
        },
        watch:{
            'modalShow'(val) {
                this.isModalVisible = val
            }
        }
    }
</script>

<style scoped>
    .button-modal-close{
        border: none !important;
        background: white !important;
        color: black !important;
        font-weight: bold;
        font-size: 1.6rem;
    }

    .button-modal-close:active, .button-modal-close:hover, .button-modal-close:focus{
        box-shadow: 0 8px 25px -8px black !important; ;
    }

    .scroll-custom ::-webkit-scrollbar {
    width: 10px;
    }

    /* Track */
    .scroll-custom ::-webkit-scrollbar-track {
    background: #f1f1f1;
    }

    /* Handle */
    .scroll-custom ::-webkit-scrollbar-thumb {
    background: #888;
    }

    /* Handle on hover */
    .scroll-custom ::-webkit-scrollbar-thumb:hover {
    background: rgb(0, 0, 0);
    }
    .header{
      font-weight: bold;
      font-size: 20px;
      padding-bottom: 0.5rem;
    }
</style>