<template>
    <BModal hide-footer v-model="isModalVisible" @hide="$emit('close')" @close="$emit('close')">
        <ValidationObserver ref="ModalAddress">
            <validation-provider #default="{ errors }" name="Ime" rules="required|min:3|max:30" mode="passive">
                <InputField :profile="profile" label="Ime" class="mb-1" v-model="address.name"/>
                <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
            <validation-provider #default="{ errors }" name="Priimek" rules="required|min:3|max:30">
                <InputField :profile="profile" label="Priimek" class="my-1" v-model="address.surname"/>
                <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
            <validation-provider #default="{ errors }" name="Naslov 1" rules="required|min:2|max:50">
                <InputField :profile="profile" label="Naslov 1" class="mt-1 mb-1" v-model="address.street"/>
                <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
            <validation-provider #default="{ errors }" name="Naslov 2" rules="">
                <InputField :profile="profile" label="Naslov 2" class="my-1" v-model="address.street2"/>
                <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
            <b-row class="mb-1">
                <b-col cols="6">
                    <validation-provider class="" #default="{ errors }" name="Mesto" rules="required|min:2|max:35">
                        <InputField :profile="profile" label="Mesto" class=" -100" v-model="address.city"/>
                        <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                </b-col>
                <b-col cols="6">
                    <validation-provider class="" #default="{ errors }" name="Postna številka" rules="required|min:4|max:4">
                        <InputField :profile="profile" label="Poštna številka" class="w-100" v-model="address.post"/>
                        <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                </b-col>
            </b-row>
            <InputField :profile="profile" label="Država" :is-disabled="true" class="w-100" v-model="address.country"/>
            <validation-provider #default="{ errors }" name="E-mail" rules="required|email">
                <InputField :profile="profile" label="E-mail" class="my-1" v-model="address.email"/>
                <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
            <validation-provider #default="{ errors }" name="Telefon" rules="required|phone_number">
                <InputField :profile="profile" v-model="address.telephone_number" label="Telefon"/>
                <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
        </ValidationObserver>
        <b-row class="my-3">
            <b-col cols="6" class="d-flex justify-content-center">
                <rounded-button font-size="18px" padding="0.6rem 4rem" class="w-100" @click="$emit('close')">Prekliči</rounded-button>
            </b-col>
            <b-col cols="6" class="d-flex justify-content-center">
                <rounded-button font-size="18px" padding="0.6rem 4rem" class="w-100" @click="validateData">Potrdi</rounded-button>
            </b-col>
        </b-row>
    </BModal>
</template>

<script>
    import {BModal, BRow, BCol} from 'bootstrap-vue'
    import InputField from '@/views/Components/InputField'
    import {ValidationObserver, ValidationProvider} from 'vee-validate'
    import { required, email, numeric, PhoneNumber, max } from '@validations' //email, min, atLeastOneDigit, atLeastOneUppercase
    import 'vue-phone-number-input/dist/vue-phone-number-input.css'
    import RoundedButton from '@/views/Components/RoundedButton'

    export default {
        components: {BModal, BRow, BCol, RoundedButton, ValidationProvider, ValidationObserver, InputField},
        props: {
            addressObject: {
                type: Object,
                required: false,
                default() {
                    return {}
                }
            },
            modalShow: {
                type: Boolean,
                default: false
            },
            profile: {
                type: Boolean,
                default: false
            }
        },
        data() {
            return {
                PhoneNumber,
                required,
                email,
                numeric,
                max,
                isModalVisible: false,
                address: {
                    id: null,
                    name: '',
                    surname: '',
                    street: '',
                    street2: '',
                    post: '',
                    city: '',
                    country: 'SLOVENIJA',
                    country_code: '705',
                    email: '',
                    telephone_number: '',
                    active: true
                }
            }
        },
        methods: {
            validateData() {
                this.$refs.ModalAddress.validate().then(success => {
                    if (success) {
                        if (this.addressObject.id !== null && this.addressObject.id !== undefined) {
                            this.updateAddress()
                        } else {
                            this.addAddress()
                        }
                    }
                })
            },
            addAddress() {
                const thisIns = this

                this.$http.post('/api/user/v1/address/add/', this.address)
                    .then(() => {
                        thisIns.$printSuccess('Naslov dodan')
                        thisIns.$emit('refresh')
                    })
                    .catch(error => {
                        thisIns.$printError(`Pri posodabljanju podatkov je prišlo do napake!\n${error.message}`)
                    })
            },

            updateAddress() {
                const thisIns = this
                this.address.id = this.addressObject.id
                this.$http.post('/api/user/v1/address/update/', this.address)
                    .then(() => {
                        thisIns.$printSuccess('Naslov posodobljen!')
                        this.address.id = null
                        thisIns.$emit('refresh')
                    })
                    .catch(error => {
                        thisIns.$printError(`Pri posodabljanju podatkov je prišlo do napake!\n${error.message}`)
                    })
            }
        },
        watch:{
            'modalShow'(val) {
                this.isModalVisible = val
            },
            'addressObject'(val) {
                this.address.name = val.name
                this.address.surname = val.surname
                this.address.street = val.street
                this.address.street2 = val.street2
                this.address.post = val.post
                this.address.city = val.city
                this.address.email = val.email
                this.address.telephone_number = val.telephone_number
            }
        }
    }
</script>

<style scoped>
</style>