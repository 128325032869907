<template>
    <div>
        <div v-if="!textArea" class="d-flex" :class="[{'input-profile': profile}, {'input': !profile}]">
            <div class="w-100" v-bind:class="{grayBackground: grayInput, whiteBackground: isDisabled && !profile}">
                <label class="mb-0">{{ label }}</label>
                <input :class="[{'input-profile': profile}, {'input': !profile}, {grayBackground: grayInput}]" :disabled="isDisabled" ref="inputF" type="text" v-model="model"
                />
            </div>
            <BButton v-if="password" class="rounded-0 px-1" style="width: auto;" @click="visibility">PRIKAŽI</BButton>
        </div>
        <div v-else class="input input-profile">
            <label class="mb-0">{{label}}</label>
            <textarea type="text" v-model="model" rows="5" :class="[{'input-profile': profile}, {'input': !profile}, {grayBackground: grayInput}]"></textarea>
        </div>
    </div>
</template>

<script>
    import {BButton} from 'bootstrap-vue'
    export default
        {
            components: {BButton},
            model: {
                prop: 'inputVal',
                event: 'input'
            },
            data() {
                return {
                    fieldType: 'text'
                }
            },
            props: {
                profile: {
                    type: Boolean,
                    default: false
                },
                inputVal: {
                    type: [Number, String],
                    default: ''
                },
                label: {
                    type: String,
                    required: true
                },
                textArea: {
                    type: Boolean,
                    required: false,
                    default: false
                },
                password: {
                    type: Boolean,
                    required: false,
                    default: false
                },
                isDisabled: {
                    type: Boolean,
                    required: false,
                    default: false
                },
                grayInput: {
                    type: Boolean,
                    required: false,
                    default: false
                }
            },
            computed: {
                model: {
                    get() {
                        return this.inputVal
                    },
                    set(value) {
                        this.$emit('input', value)
                    }
                }
            },
            methods:{
                visibility() {
                    if (this.$refs.inputF.type === 'text') {
                        this.$refs.inputF.type = 'password'
                    } else {
                        this.$refs.inputF.type = 'text'
                    }
                }
            },
            mounted() {
                if (this.password) {
                    this.$refs.inputF.type = 'password'
                }
            }
        }
</script>

<style scoped>

.input-profile {
    background-color: #F4F4F4 !important;
    border: 1px solid #F4F4F4 !important;
    border-radius: 0 !important;
}
.input-profile::placeholder{
    text-transform: uppercase;
    color: black;
}

/* .input{
    background-color: #ffffff!important;
    border: 1px solid #c7c7c7;
    margin-top: 10px;
} */

.input label, .input-profile label {
    color: #7da5d7 !important;
    padding-left: 10px;
    padding-top: 5px;
    padding-bottom: 0;
}

.input input, .input-profile input{
    border: none;
    width: 100%;
    padding-left: 10px;
    padding-bottom: 5px;
    padding-top: 0;
}

.input input:focus, .input-profile input:focus {
    outline: none!important;
}

.input textarea, .input-profile textarea{
  border: none;
  width: 100%;
  padding-left: 10px;
  padding-bottom: 5px;
  padding-top: 0;
    resize: none;
}

.input input:disabled {
    background-color: white;
}

.input-profile input:disabled {
    background-color: red;
}

.input textarea:focus, .input-profile textarea:focus {
    outline: none !important;
}

.grayBackground {
    background-color: #e7e6e6;
}

.whiteBackground {
    background-color: white;
}
</style>