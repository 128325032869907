<template>
    <div>
        <b-row class="mt-1 basket-items">
            <b-col lg="4" md="5" sm="5" cols="12">
                <div class="d-flex justify-content-center" v-if="item.id">
                    <router-link :to="{name: 'web-shop-item', params: { item_id: item.id.replace('_coupons', '')  }}">
                        <b-img v-if="item.main_picture" fluid :src="'/api/user/v1/web_shop/product_image/500_' + item.main_picture" style="max-height: 150px;"/>
                        <b-img v-else :src="require('@/assets/images/esoseska/no-image.jpg')" style="max-height: 150px;"/>
                    </router-link>
                </div>
            </b-col>
            <b-col lg="8" md="7" sm="7" cols="12" class="mt-1 mt-sm-0">
                <div class="d-flex align-items-center justify-content-between flex-wrap">
                    <div>
                        <router-link v-if="item && item.category && item.category.title !== ''" :to="{name: 'web-shop-category-items-list', params: { category_id: item.category.id  }}">
                            <div class="text-secondary font-normal">
                                <feather-icon icon="ChevronRightIcon" size="20"/>
                                {{ item.category.title}}
                            </div>
                        </router-link>
                    </div>
                    <div v-if="item.discounted" class="ml-2 font-small">
                        <del>{{ $convertPriceIntegerToFloat(item.price) }} €</del>
                    </div>
                </div>
                <div class="d-flex align-items-center justify-content-md-between" style="margin-top: 0.2rem">
                    <div>
                        <router-link :to="{name: 'web-shop-item', params: { item_id: item.id  }}">
                            <div class="font-medium" style="color: black;">
                                {{ item.description_1 }}
                            </div>
                        </router-link>
                    </div>
                    <div v-if="item.promotional_price && promotion" class="text-right">
                        <div class="d-none d-md-block" style="margin-left: 0.4rem; white-space: nowrap; text-decoration: line-through; font-size: 12px !important; font-weight: bold;">
                            {{ $convertPriceIntegerToFloat(item.discounted ? item.price_discounted : item.price) }} €
                        </div>
                        <div class="font-big">
                            {{ $convertPriceIntegerToFloat(promotion.price) }} €
                        </div>
                    </div>
                    <div v-else class="d-none d-md-block font-big" style="margin-left: 0.4rem; white-space: nowrap;">
                        {{ $convertPriceIntegerToFloat(item.discounted ? item.price_discounted : item.price) }} €
                    </div>
                </div>
                <div class="d-flex align-items-center justify-content-between" style="margin-top: 0.2rem">
                    <div class="font-normal" v-if="item.id">
                        {{item.id.replace("_coupons", "")}}
                    </div>
                    <div class="font-normal" v-if="item.promotional_price && promotion">
                        Porabili boste <span style="color: #009FD4;">{{promotion.number_of_coupons * item.quantity}} {{ coupons_text }}</span>
                    </div>
                    <!-- <div v-if="item.points" class="font-normal">
                        TOČKE: {{item.points}}
                    </div> -->
                </div>
                <div class="d-flex d-sm-block align-items-end justify-content-between" style="margin-top: 0.4rem">
                    <div class="d-flex justify-content-between align-items-center">
                        <b-form-spinbutton @change="onItemQuantityChange" size="lg" v-model="item.quantity" min="1" :max="item.in_stock" inline style="background-color: #e7e6e6; border: none; font-weight: bolder;"/>
                        <div class="d-flex align-items-center" v-if="promotion">
                            <div class="mr-1">
                                Uporabi kupone: 
                            </div>
                            <b-form-checkbox @change="changePromotion" v-model="item.promotional_price"></b-form-checkbox>
                        </div>
                    </div>

                    <div class="d-md-none font-big" style="white-space: nowrap;">
                        {{ $convertPriceIntegerToFloat(item.discounted ? item.price_discounted : item.price) }} €
                    </div>
                </div>
            </b-col>
        </b-row>
        <b-row align-h="between">
            <b-col order="2" order-sm="1" cols="12" sm="5" lg="4">
                <div class="d-flex justify-content-start mt-1">
                    <a @click="onRemoveItemFromShoppingCart">
                        <div class="d-flex align-items-center text-center text-secondary font-normal" style="font-weight: 700;">
                            <b-avatar class="mr-1" style="color: #7da5d7;">
                                <b-img class="" style="height: 1.5rem" src="@/assets/images/pages/shoppingCard/trash-can.svg"/>
                            </b-avatar>
                            Odstrani iz košarice
                        </div>
                    </a>
                </div>
            </b-col>
            <b-col order="1" order-sm="2" cols="12" sm="7" lg="8">
                <b-row class="pt-1 lg:pt-0" align-h="between">
                    <b-col class="d-flex justify-content-start align-items-end">
                        <div class="font-normal" style="font-weight: 700;">Total:</div>
                    </b-col>
                    <b-col class="d-flex justify-content-end">
                        <div>
                            <div class="font-big" style="white-space: nowrap;" v-if="item.promotional_price && promotion">
                                {{ $convertPriceIntegerToFloat(promotion.price * item.quantity) }} €
                            </div>
                            <div class="font-big" style="white-space: nowrap;" v-else>
                                {{ $convertPriceIntegerToFloat(item.total_price)}} €
                            </div>
                        </div>
                    </b-col>
                </b-row>
            </b-col>
        </b-row>
        <div class="mt-1 hr"/>
    </div>
</template>

<script>
    /*import CheckboxField from './CheckboxField'*/
    import {BRow, BCol, BFormSpinbutton, BImg, BAvatar, BFormCheckbox} from 'bootstrap-vue'

    export default {
        components: {
            /*CheckboxField,*/
            BRow,
            BCol,
            BFormSpinbutton,
            BImg,
            BAvatar,
            BFormCheckbox
        },
        props: {
            item: {
                type: Object,
                required: true
            }
        },
        data() {
            return {
                quantity: 0,
                categories: [],
                promotion: null,
                coupons_text: ''
            }
        },
        methods: {
            onAddItemToShoppingCart(id) {
                const data = {
                    item: this.item,
                    quantity: parseInt(this.item.quantity),
                    id,
                    id_to_remove: `${id}_coupons`
                }

                this.$store.dispatch('shoppingCart/changePromotion', data)
            },  
            addItemToCart(id) {
                const data = {
                    item: this.item,
                    quantity: parseInt(this.item.quantity),
                    promotional_price: true,
                    price_with_coupons: this.promotion.price,
                    id: `${id}_coupons`,
                    id_to_remove: id
                }

                this.$store.dispatch('shoppingCart/changePromotion', data)
               
            },
            changePromotion(checked) {

                const itemID = this.item.id.replace('_coupons', '')

                if (!checked) {
                    this.onAddItemToShoppingCart(itemID)
                    // await this.$store.dispatch('shoppingCart/removeItemFromCartByItemID', `${itemID  }_coupons`)
                    //     .then(() => { this.$emit('on-item-removed-from-shopping-cart') })
                } else {
                    this.addItemToCart(itemID)
                    // await this.$store.dispatch('shoppingCart/removeItemFromCartByItemID', `${itemID  }`)
                    //     .then(() => { this.$emit('on-item-removed-from-shopping-cart') })
                }
                this.$emit('refreshShoppingCart')         
                // this.$emit('on-item-removed-from-shopping-cart')   
                // location.reload()
            },
            onItemQuantityChange() {

                let modifiedPrice = 0

                if (this.promotion && this.promotion.price) {
                    modifiedPrice = this.promotion.price
                }

                const data = {
                    item_id: this.item.id,
                    quantity: this.item.quantity,
                    price_with_coupons: modifiedPrice,
                    max_stock: this.item.in_stock
                }
                this.$store.dispatch('shoppingCart/updateItemQuantity', data)
                this.$emit('refreshShoppingCart')         

            },
            onRemoveItemFromShoppingCart() {
                this.$store.dispatch('shoppingCart/removeItemFromCartByItemID', this.item.id)

                this.$emit('refreshShoppingCart')
            },
            async loadPromotions() {
                try {
                    const res = await this.$http.get(`/api/user/v1/promotions/products/${this.item.id.replace('_coupons', '')}`)

                    if (res.data && res.data.bbis_id === this.item.id.replace('_coupons', '')) {
                        this.promotion = res.data
                    }

                    if (this.promotion && (this.promotion.number_of_coupons * this.item.quantity) === 1) {
                        this.coupons_text = 'kupon'
                    } else if (this.promotion && (this.promotion.number_of_coupons * this.item.quantity) >= 2) {
                        this.coupons_text = 'kuponov'
                    }
                  
                } catch (error) {
                    this.$printError('Pri nalaganju izdelkov je prišlo do napake!')
                }

            }
        },
        async mounted() {
            await this.loadPromotions()
        }
    }
</script>

<style scoped>
.hr{
  border-top: 2px solid #E6E6E6;
  width: 100%;
}
.font-small {
  font-weight: 400;
  font-size: 15px;
  line-height: 19px;
}
.font-normal {
  font-weight: 400;
  font-size: 17px;
  line-height: 23px;
}
.font-medium {
  font-weight: 700;
  font-size: 20px;
  line-height: 25px;
}
.font-big {
  font-weight: 700;
  font-size: 25px;
  line-height: 31px;
}
</style>

<style>
.basket-items .b-form-spinbutton {
    border: 2px solid #C4C4C4;
    border-radius: 0;
}
.basket-items .b-form-spinbutton output {
    border: none !important;
}
</style>