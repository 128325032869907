<template>
    <b-overlay :show="showLoader">
        <ShoppingCardFirst ref="shopingCartFirst" v-on:refreshShoppingCart="loadData" v-if="active === 0" v-model="active" :validMap="validMap" :items-to-display="itemsToDisplay" :swiper-data="swiperData" :shipping-price="shippingPrice" @get-shipping-price="getShippingPriceForCartIfNotLoggedIn"/>
        <ShoppingCardSecond v-else-if="active === 1" v-model="active" :items="items" :items-to-display="itemsToDisplay" :swiper-data="swiperData" :shipping-price="shippingPrice" @show-loader="loader"/>
    </b-overlay>
</template>

<script>
    import ShoppingCardFirst from '@/views/Pages/ShoppingCard/ShoppingCardFirst'
    import ShoppingCardSecond from '@/views/Pages/ShoppingCard/ShoppingCardSecond'
    import {BOverlay} from 'bootstrap-vue'
    export default {
        components: {ShoppingCardSecond, ShoppingCardFirst, BOverlay},
        data() {
            return {
                showLoader: false,
                active: 0,
                itemsToDisplay: [],
                swiperData: [],
                categoriesInCart: [],
                validMap: null
            }
        },
        methods: {
            async loadData() {
                this.showLoader = true
                try {
                    // await this.$store.dispatch('shoppingCart/getUserShoppingCart')
                    await this.loadItemsFromCart()
                    await this.getShippingPriceForCartIfNotLoggedIn()
                    await this.checkIfShopingCartIsValid()
                    await this.loadOtherItems()
                    this.$refs.shopingCartFirst.loadNumberOfCoupons()

                } catch (error) {
                    this.$printError(`Prišlo je do napake pri nalaganju podatkov\n${error.message}`)
                } finally {
                    this.showLoader = false
                }
            },
            async loadItemsFromCart() {
                const itemIds = []
                this.items.map(item => {
                    itemIds.push(item.id)
                })

                const res = await this.$http.post('/api/user/v1/web_shop/item_by_ids', itemIds)

                const itemsFromAPI = res.data

                const itemsApiIndexMap = {}

                for (const i in itemsFromAPI) {
                    itemsApiIndexMap[itemsFromAPI[i].id] = i
                }

                const tmpItems = itemsFromAPI.map(item => {
                    const foundItemInCart = this.items.find(i => i.id === item.id)

                    if (foundItemInCart) {
                        item.quantity = foundItemInCart.quantity
                    }
                    return item
                })

                await this.$store.dispatch('shoppingCart/setAllItemsProperties', tmpItems)
                this.itemsToDisplay = tmpItems
                // await this.$store.dispatch('shoppingCart/updateCartState')

            },
            async getShippingPriceForCartIfNotLoggedIn() {
                if (this.isLoggedIn) return Promise.resolve()

                const response = await this.$http.put('/api/user/v1/web_shop/cart/shipping_price', this.$store.getters['shoppingCart/cart'])
                await this.$store.dispatch('shoppingCart/setShippingPrice', response.data)
            },
            async checkIfShopingCartIsValid() {

                this.validMap = {}

                const ids_to_delete = []
                const items_update_quantity = []
                const items_stock = {}

                for (const item of this.items) {

                    const product_id = item.id.replace('_coupons', '')

                    if (item.in_stock < item.quantity) {
                        this.validMap[item.id] = {
                            name: item.description_1,
                            oldQuantity: item.quantity,
                            newQuantity: item.in_stock,
                            pic: item.main_picture
                        }

                        if (item.in_stock === 0) {
                            ids_to_delete.push(item.id)
                        } else {
                            const data = {
                                item_id: item.id,
                                quantity: item.in_stock
                            }
                            items_update_quantity.push(data)
                        }


                        //Prevermo ce mamo 2 artikla notr z kuponi in brez da stock nepresega skupnega stocka najprej pristejemo in potem preverimo
                        if (!items_stock[product_id]) {
                            items_stock[product_id] = item.in_stock
                        } else {
                            items_stock[product_id] += item.in_stock
                        }
                    } else if (item.in_stock >= item.quantity) {

                        if (!items_stock[product_id]) {
                            items_stock[product_id] = item.quantity
                        } else {
                            items_stock[product_id] += item.quantity
                        }

                    }

                    if (items_stock[product_id] && items_stock[product_id] > item.in_stock) {

                        let new_quantity = 0
                       

                        if (item.id.includes('_coupons')) {
                            new_quantity = item.in_stock - item.quantity
                        } else {
                            new_quantity = item.in_stock - (items_stock[product_id] - item.quantity)
                        }

                        this.validMap[product_id] = {
                            name: item.description_1,
                            oldQuantity: items_stock[product_id],
                            newQuantity: item.in_stock,
                            pic: item.main_picture
                        }


                        if (new_quantity <= 0) {
                            ids_to_delete.push(product_id)
                        } else {
                          

                            const data = {
                                item_id: product_id,
                                quantity: new_quantity
                            }
                            items_update_quantity.push(data)
                        }
                       
                    }


                    if (items_stock[item.id.replace('_coupons', '')]) if (item.split_categories && item.split_categories.length > 0) this.categoriesInCart.push(item.split_categories[0])
                }

                for (const id of ids_to_delete) {
                    await this.$store.dispatch('shoppingCart/removeItemFromCartByItemID', id)
                }

                for (const item of items_update_quantity) {
                    await this.$store.dispatch('shoppingCart/updateItemQuantity', item)
                }

                await this.$store.dispatch('shoppingCart/updateCartState')
                await this.$store.dispatch('shoppingCart/syncUserShoppingCart')

                this.itemsToDisplay = this.items

            },
            getQueryParams() {
                let  params = ''
                for (const category of this.categoriesInCart) {
                    params += `split_categories.[]=${category}&&`
                }
                return params
            },
            async loadOtherItems() {
                const queryParams = this.getQueryParams()

                const res = await this.$http.get(`/api/user/v1/web_shop/?${queryParams}&perPage=9`)
                this.swiperData = res.data.items || []
            },
            loader(val) {
                this.showLoader = val
            }
        },
        computed: {
            items() {
                return this.$store.getters['shoppingCart/items']
            },
            shippingPrice() {
                return this.$store.getters['shoppingCart/shippingPrice'] || 0
            }
        },
        async mounted() {
            this.$scrollToTop()
            await this.loadData()
        },
        watch: {
            async items() {
                // if data is loading from API, ignore
                if (this.showLoader) return
                this.showLoader = true

                try {
                    await this.loadItemsFromCart()

                } catch (error) {
                    this.$printError(`Prišlo je do napake pri nalaganju podatkov 123 123 123  123\n${error.message}`)
                } finally {
                    this.showLoader = false
                }
            }
        }
    }
</script>

<style scoped>

</style>